// extracted by mini-css-extract-plugin
export var crossLinksSection = "about-module--crossLinksSection--5c7a4";
export var descriptionCard = "about-module--descriptionCard--4dc4e";
export var flipCardIn = "about-module--flipCardIn--0b517";
export var flippedImage = "about-module--flippedImage--6b2d3";
export var infoCard = "about-module--infoCard--198db";
export var photoContainer = "about-module--photoContainer--1c20c";
export var photoOverlay = "about-module--photoOverlay--78bc9";
export var profilePhoto = "about-module--profilePhoto--af2f4";
export var skillsContainer = "about-module--skillsContainer--56fc3";
export var slideIn = "about-module--slideIn--206a9";
export var slidePhotoDown = "about-module--slidePhotoDown--8cae0";
export var subtitle = "about-module--subtitle--a8ab3";
export var title = "about-module--title--7dbc9";