//React
import * as React from 'react';
import { useState } from 'react';

//Styles
import * as styles from './about.module.scss';

//Components
import Layout from '../../components//Layout//Layout';
import Card from '../../components/Card/Card';
import PageFade from '../../components/PageContainer/PageContainer';
import Seo from '../../components/seo';

//Images
import pixelProfile from '../../images/pixel-profile.jpg';
import headshot from '../../images/headshot.jpg';
import htmlIcon from '../../images/html-icon.png';
import cssIcon from '../../images/css-icon.png';
import javascriptIcon from '../../images/javascript-icon.png';
import gatsbyIcon from '../../images/gatsby-logo.png';
import reactIcon from '../../images/react-icon.png';
import nextjsIcon from '../../images/nextjs-icon.png';
import firebaseIcon from '../../images/firebase-icon.png';
import sketchIcon from '../../images/sketch-logo.png';
import invisionIcon from '../../images/invision-logo.png';
import uxTestingIcon from '../../images/usability-testing.png';
import uxResearchIcon from '../../images/user-research.png';
import photoshopIcon from '../../images/photoshop-icon.png';
import illustratorIcon from '../../images/illustrator-icon.png';
import indesignIcon from '../../images/indesign-icon.png';
import { Link } from 'gatsby';

const About = ({ location }) => {
    const [imageStyles, setImageStyles] = useState([styles.photoContainer]);
    const [shownImage, setShownImage] = useState('pixel');

    const flipImage = () => {
        if (!imageStyles.includes(styles.flippedImage)) {
            setImageStyles([styles.photoContainer, styles.flippedImage]);
            setTimeout(() => {
                setShownImage('headshot');
            }, 200)
        }
        else {
            setImageStyles([styles.photoContainer])
            setTimeout(() => {
                setShownImage('pixel');
            }, 200)
        }

    }

    return (
        <Layout location={location}>
            <Seo
                title={"About"}
                description={"Learn about Dan Kolonay's skills in web development and UX design."}
            />
            <main id='about-page'>
                <PageFade>
                    <h1 className={styles.title}>Hi, I'm Dan!</h1>
                    <div className={imageStyles.join(' ')} onClick={flipImage}>
                        <img className={styles.profilePhoto} src={pixelProfile} alt="Dan Kolonay" style={{ opacity: shownImage === 'pixel' ? 1 : 0 }} />                        
                        <img className={styles.profilePhoto} src={headshot} alt="Dan Kolonay" style={{ opacity: shownImage === 'headshot' ? 1 : 0 }}/>                        
                        <div className={styles.photoOverlay}></div>
                    </div>
                    <h2 className={styles.subtitle}> I'm a web developer and UX designer specializing in React development.</h2>

                    {/* <Card addedClasses={[styles.infoCard]}>
                        <h1> Hi, I'm Dan!</h1>
                        <p>I'm a web developer and UX designer specializing in React development.
                            I'm a web developer and UX designer specializing in React development.  </p>
                    </Card> */}


                    <div className={styles.skillsContainer}>

                        <Card addedClasses={[styles.descriptionCard]}>
                            <h2>Front-end Development</h2>
                            <p>
                                I specialize in front end development using the React library as a framework for my designs, and
                                I've recently started implementing Gatsby in my projects to solve the SEO complications that arrise from
                                standard single page apps made in React.
                            </p>
                            <p>
                                While I'm not too familiar with
                                back end programming, I'm proficient in interfacing with services like Firebase and Gatsby cloud
                                to manage and secure data in my projects.
                            </p>
                            <p>
                                Although I typically use React and SCSS to create my projects, I have a strong understanding
                                of the base syntax of HTML, CSS, and Javascript
                            </p>
                        </Card>
                        <ul>
                            <li><img src={htmlIcon} alt="HTML" /><p>HTML</p></li>
                            <li><img src={cssIcon} alt="CSS" /><p>CSS / SASS</p></li>
                            <li><img src={javascriptIcon} alt="Javascript" /><p>Javascript</p></li>
                            <li><img src={reactIcon} alt="React" /><p>React</p></li>
                            <li><img src={gatsbyIcon} alt="Gatsby" /><p>Gatsby</p></li>
                            <li><img src={nextjsIcon} alt="NextJS" /><p>Next.js</p></li>
                            <li><img src={firebaseIcon} alt="Firebase" /><p>Firebase</p></li>
                        </ul>
                    </div>
                    <div className={styles.skillsContainer}>
                        <Card addedClasses={[styles.descriptionCard]}>
                            <h2>UX Design</h2>
                            <p>
                                My interest in web development started through the lense of UX design while taking classes at
                                General Assembly.  Since then, I've learned that I'm more interested in the programming side
                                of development, but I haven't lost sight of considering the end user in every decision I make
                                while programming.
                            </p>
                        </Card>
                        <ul>
                            <li><img src={sketchIcon} alt="Sketch" /><p>Sketch Wireframes</p></li>
                            <li><img src={invisionIcon} alt="InVision" /><p>InVision Prototypes</p></li>
                            <li><img src={uxTestingIcon} alt="Usability Testing" /><p>Usability Testing</p></li>
                            <li><img src={uxResearchIcon} alt="User Research" /><p>User Research</p></li>
                        </ul>
                    </div>
                    <div className={styles.skillsContainer}>
                        <Card addedClasses={[styles.descriptionCard]}>
                            <h2>Graphic Design</h2>
                            <p>
                                I'm not a graphic designer by trade -- you could say it's not my passion -- but
                                I've taken the time to learn image editing and the basics of vector drawing in the
                                Adobe Creative Suite since these skills are often so necessary for working on solo projects
                                in web development.

                            </p>
                        </Card>
                        <ul>
                            <li><img src={photoshopIcon} alt="Photoshop" /><p>Photoshop</p></li>
                            <li><img src={illustratorIcon} alt="Illustrator" /><p>Illustrator</p></li>
                            <li><img src={indesignIcon} alt="InDesign" /><p>InDesign</p></li>
                        </ul>

                    </div>
                    <div className={styles.crossLinksSection}>
                        <h3>Wanna see what I can do with these skills?</h3>
                        <Link to={'/my-work'}>Check out my work</Link>
                    </div>

                </PageFade>
            </main>
        </Layout>
    )
}

export default About